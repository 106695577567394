import { DIMENSIONS, Ratio } from '@canalplus/mycanal-commons';
import { ApiV2Context } from '@dce-front/hodor-types/api/v2/common/dto/definitions';
import type { ApiV2BroadcastChannel } from '@dce-front/hodor-types/api/v2/page/dtos/display_templates/live_grid/definitions';
import { useMemo } from 'react';
import { StrateMode } from '../../../constants/strates';
import { formatLiveGridPlayerData } from '../../../helpers/liveTV/liveTV-helper';
import I18n from '../../../lang';
import CardConnected from '../../Card/CardConnected';
import Linker from '../../Linker/Linker';
import StartOverButton from '../../StartOverButton/StartOverButton';

export type LiveGridCardProps = {
  channel: ApiV2BroadcastChannel;
  context?: ApiV2Context;
  isTvDevice?: boolean;
  ratio: string;
};

function LiveGridCard({ channel, context, isTvDevice = false, ratio }: LiveGridCardProps): JSX.Element {
  const { t } = I18n.useTranslation();
  const { onClick, ...rest } = useMemo(() => formatLiveGridPlayerData(channel, context) || {}, [channel, context]);

  const startOverTitle = channel?.contents?.[0]?.title || '';

  const content = channel.contents && channel.contents[0];
  const title = content ? content.title : t('LiveGrid.cards.genericTitle');
  const subtitle = content ? content.subtitle : t('LiveGrid.cards.genericSubtitle');

  const logoChannel = useMemo(
    () => (channel.URLLogoChannel ? { default: channel.URLLogoChannel } : undefined),
    [channel]
  );

  return (
    <Linker
      // `rest` contains an **channel** object with an **epgID** property
      // used in the `launchPlayerFullScreen` Linker function
      // ⚠️ All this stuff isn't properly typed
      data={{ mainOnClick: onClick, ...rest }}
      title={title}
      objKey={StrateMode.LiveTv}
      id={`${String(channel?.epgID)}_onclick`}
    >
      <CardConnected
        dimensions={isTvDevice ? DIMENSIONS[ratio].large : DIMENSIONS[ratio].normal}
        action={<StartOverButton context={context} content={channel} title={startOverTitle} />}
        image={{ default: content?.URLImage }}
        ratio={ratio as Ratio}
        title={title}
        subtitle={subtitle}
        onGoing={
          content && {
            endTime: content.endTime,
            startTime: content.startTime,
          }
        }
        altLogoChannel={channel.Name}
        logoChannel={logoChannel}
        isLiveIconEnabled={false}
        isLifecycleLabelEnabled={false}
        forceDisplayTitle
        isLiveGridCard
      />
    </Linker>
  );
}

export default LiveGridCard;
