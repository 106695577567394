import { ButtonShapes } from '../../../constants/button';
import { TemplateTypes } from '../../../constants/templateTypes';
import I18n from '../../../lang';
import Button from '../../Button/Button';
import styles from './ProspectLoginMessage.css';

export type ProspectLoginMessageProps = {
  /** subscribe url for live TV */
  subscribeUrlLive?: string;
};

/** A very dumb component used to isolate the prospect login message for easier testing */
export function ProspectLoginMessage({ subscribeUrlLive }: ProspectLoginMessageProps): JSX.Element {
  const { t } = I18n.useTranslation();

  return (
    <div className={styles.prospectLoginMessage}>
      <p>
        {t('ProspectLoginMessage.message')}
        <br />
        <strong>{t('ProspectLoginMessage.commitment')}</strong>
      </p>
      <Button
        className={`${styles.prospectLoginMessage__button}`}
        color={TemplateTypes.SECONDARY}
        shape={ButtonShapes.ROUND}
        text={t('ProspectLoginMessage.offer')}
        link={subscribeUrlLive}
        isBlank
      />
    </div>
  );
}
