import { DEFAULT_RATIO, isRatio, Ratio } from '@canalplus/mycanal-commons';
import { Binder, useStore } from '@canalplus/one-navigation';
import { Template } from '@canalplus/sdk-hodor';
import { ApiV2Context } from '@dce-front/hodor-types/api/v2/common/dto/definitions';
import { ApiV2LiveGridFeature } from '@dce-front/hodor-types/api/v2/live_grid/definitions';
import type { ApiV2BroadcastChannel } from '@dce-front/hodor-types/api/v2/page/dtos/display_templates/live_grid/definitions';
import classNames from 'classnames';
import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Card from '../../../components/Card/Card';
import LazyLoader from '../../../components/LazyLoader/LazyLoader';
import LiveGridCard from '../../../components/LiveGrid/LiveGridCard/LiveGridCard';
import LiveGridFeatureCard from '../../../components/LiveGrid/LiveGridFeatureCard/LiveGridFeatureCard';
import { ProspectLoginMessageConnected } from '../../../components/LiveGrid/ProspectLoginMessage/ProspectLoginMessageConnected';
import { getLazyLoaderContentGridOptions, StrateMode } from '../../../constants/strates';
import { getPublicConfig } from '../../../helpers/config/config-helper';
import { useAppDispatch } from '../../../helpers/hooks/useAppDispatch';
import { useLiveChannelsFilter } from '../../../helpers/liveTV/liveTV-helper';
import { MIDDLEWARE_STRATE, MIDDLEWARE_STRATE_PERSO } from '../../../helpers/oneNavigation/middleware';
import I18n from '../../../lang';
import {
  getFeatureToggleMultiLiveSelector,
  userStatusSelector,
} from '../../../selectors/application/application-selectors';
import { launchPlayerFullScreen } from '../../../store/slices/player-thunk';
import { locationSearchChannelSelector } from '../../../store/slices/routing-selectors';
import styles from './LiveGridTemplate.css';

export type LiveGridTemplateProps = {
  channels: ApiV2BroadcastChannel[];
  nbPlaceholderItems: number;
  context?: ApiV2Context;
  displayPlaceholderChannels?: boolean;
  features?: ApiV2LiveGridFeature[];
  isAdult?: boolean;
  isPerso?: boolean;
  isTvDevice?: boolean;
  ratio?: Ratio;
  refetchLiveGridRequest: () => void;
};

function LiveGridTemplate({
  channels,
  context,
  displayPlaceholderChannels = false,
  features = [],
  isAdult,
  isPerso = false,
  isTvDevice = false,
  nbPlaceholderItems,
  ratio = DEFAULT_RATIO,
  refetchLiveGridRequest,
}: LiveGridTemplateProps): JSX.Element {
  const dispatch = useAppDispatch();
  const userStatus = useSelector(userStatusSelector);
  const isProspect = userStatus === getPublicConfig().user_status.prospect;
  const oneNavStore = useStore();
  const { t } = I18n.useTranslation();

  const isFeatMultiLive = useSelector(getFeatureToggleMultiLiveSelector);
  const epgIdToLaunchOnMount = useSelector(locationSearchChannelSelector);

  const imageRatio = isRatio(DEFAULT_RATIO) ? DEFAULT_RATIO : Ratio.Ratio169;

  const filteredChannels = useLiveChannelsFilter(channels, refetchLiveGridRequest, displayPlaceholderChannels);

  useEffect(() => {
    const layer = oneNavStore.getActiveLayer();
    layer?.focusDefault();
    if (isPerso && layer?.current && layer?.currentBinder) {
      layer.currentBinder.callFocusedHook(layer.current);
    }
  }, [filteredChannels, isPerso, oneNavStore]);

  // If a channel parameter is in the URL, we launch that channel
  useEffect(() => {
    if (epgIdToLaunchOnMount) {
      /* object structure is to appease getContentID helper in fullscreen-helper.ts */
      const channelToLaunch = { channel: { epgID: epgIdToLaunchOnMount } };
      dispatch(launchPlayerFullScreen({ data: channelToLaunch, type: StrateMode.LiveTv }));
    }
  }, [dispatch, epgIdToLaunchOnMount]);

  const wrapCard = useCallback(
    (element, key) => (
      <li
        className={classNames(styles.LiveGridTemplate__gridItem, styles['liveGrid--focus'])}
        data-ratio={imageRatio}
        key={String(key)}
        id={String(key)}
      >
        {element}
      </li>
    ),
    [imageRatio]
  );

  // If no channels are present, render placeholder cards.
  const listToRender: (ApiV2BroadcastChannel | undefined)[] = filteredChannels?.length
    ? filteredChannels
    : Array.from({ length: nbPlaceholderItems });

  const multiliveFeature = features.find((feature) => feature?.onClick?.displayTemplate === Template.MultiLiveSetup);
  const hasMultiliveFeature = isFeatMultiLive ? Boolean(multiliveFeature) : false;

  const { increment, initialDisplayCount } = getLazyLoaderContentGridOptions(isTvDevice, false);

  return (
    <Binder middleware={isAdult ? MIDDLEWARE_STRATE_PERSO : MIDDLEWARE_STRATE} forceFocusOnMount={isAdult}>
      <ul className={classNames(styles.LiveGridTemplate__grid, 'liveGrid')} aria-label={t('LiveGrid.ariaLabel')}>
        <LazyLoader
          initialDisplayCount={initialDisplayCount}
          loadMoreCount={increment}
          enableLazyLoad={!!filteredChannels?.length}
        >
          {/* Multi-live setup card */}
          {hasMultiliveFeature &&
            wrapCard(
              <LiveGridFeatureCard
                displayPlaceholderChannels={displayPlaceholderChannels}
                feature={multiliveFeature}
                isPerso={isPerso}
                isTvDevice={isTvDevice}
                ratio={imageRatio}
              />,
              multiliveFeature?.contentID
            )}

          {/* Channels */}
          {listToRender.map((channel, index: number) => {
            const id = (typeof channel !== 'undefined' && channel?.epgID) || index;

            const trackingContext: ApiV2Context | undefined = context
              ? { ...context, context_list_position: index + 1 }
              : undefined;

            return wrapCard(
              channel ? (
                <LiveGridCard channel={channel} context={trackingContext} ratio={ratio} isTvDevice={isTvDevice} />
              ) : (
                // @ts-expect-error Now that Card is properly typed, we need to pass the correct props to it.
                <Card ratio={ratio} isLiveGridCard />
              ),
              id
            );
          })}
        </LazyLoader>
      </ul>
      {isProspect && (
        <div className={styles.LiveGridTemplate__prospectLoginMessageWrap}>
          <ProspectLoginMessageConnected />
        </div>
      )}
    </Binder>
  );
}

export default LiveGridTemplate;
